
import Vue from "vue";
import Logo from "@/components/common/Logo.vue";

export default Vue.extend({
  name: "SignupNavigate",
  components: { Logo },
  data() {
    return {
      image: require("/src/assets/Vertical-Gas-Station-quarter-container.jpg"),
      invites: [{ exists: false, email: "" }],
      isMember: false // to do
    };
  },
  computed: {
    title: function (): string {
      if (this.$route.query.accountTransfer) {
        return "Success! Transfer ownership is complete.";
      }
      return "What would you like to start with?";
    },
  },
  methods: {
    toDashboard() {
      this.$router.push("/dashboard");
    },
    toAccount() {
      this.$router.push("/accountmanagement");
    },
    toProfile() {
      this.$router.push("/users/" + this.$store.state.associate.id);
    },
  },
});
